import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Settings.css';
import Button from './Button';
import LanguagesList from './LanguagesList';
import { FaStethoscope } from 'react-icons/fa';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Settings() {
    const [username, setUsername] = useState('Guest');
    const [email, setEmail] = useState('');
    const [emailToggle, setEmailToggle] = useState(false);  // État pour gérer emailenabled
    const [vocabulary, setVocabulary] = useState([]);  // Gérer le vocabulaire comme un tableau
    const [newVocab, setNewVocab] = useState('');
    const [selectedVocabulary, setSelectedVocabulary] = useState('');
    const [reportLanguage, setReportLanguage] = useState('français');
    const [dictationLanguage, setDictationLanguage] = useState('fr');
    const [hasChanges, setHasChanges] = useState(false);

    const isRefreshingRef = useRef(false);

    useEffect(() => {
        // Fetch settings from the server
        const fetchSettings = async () => {
          if (isRefreshingRef.current) {
            // Si le cooldown est actif, ne pas exécuter le rafraîchissement
            console.log('Veuillez attendre avant de rafraîchir à nouveau.');
            return;
          }
      
          // Activer le cooldown
          isRefreshingRef.current = true;
      
          // Démarrer le timer pour réinitialiser le cooldown après 5 secondes
          setTimeout(() => {
            isRefreshingRef.current = false;
          }, 5000);
      

          
            try {
                const chatId = localStorage.getItem('chatId');
                const response = await axios.post(`${API_BASE_URL}/getSettings`, { chatId });
                const data = response.data;

                setUsername(data.name || 'Guest');
                setEmail(data.email || '');
                setEmailToggle(Boolean(data.emailenabled));  // Convertir correctement en booléen
                setVocabulary(data.voc ? data.voc.split(',').map(word => word.trim()).filter(word => word) : []);  // Convertir la chaîne en tableau
                setReportLanguage(data.langue || 'français');
                setDictationLanguage(data.french || 'fr');
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        fetchSettings();
    }, []);

    const handleSave = async () => {
        try {
            const chatId = localStorage.getItem('chatId');

            // Convert vocabulary array back to a comma-separated string
            const vocabularyString = vocabulary.join(', ');

            const response = await axios.post(`${API_BASE_URL}/updateSettings`, {
                chatId,
                email,
                emailenabled: emailToggle,  // Envoyer la bonne valeur pour emailenabled
                vocabulary: vocabularyString,  // Envoyer le vocabulaire comme une chaîne
                french: dictationLanguage,
                langue: reportLanguage
            });

            if (response.status === 200) {
                alert('Réglages sauvegardés avec succès');
                setHasChanges(false);
            }
        } catch (error) {
            console.error('Erreur lors de la sauvegarde des réglages:', error);
            alert('Erreur lors de la sauvegarde des réglages');
        }
    };

    const handleToggleChange = (setter) => (e) => {
        setter(e.target.checked);
        setHasChanges(true);
    };

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setHasChanges(true);
    };

    const handleAddVocabulary = () => {
        if (newVocab && !vocabulary.includes(newVocab.trim())) {
            setVocabulary([...vocabulary, newVocab.trim()]);  // Ajouter le mot à la liste
            setNewVocab('');  // Vider le champ après ajout
            setHasChanges(true);
        }
    };

    const handleRemoveVocabulary = () => {
        const updatedVocabulary = vocabulary.filter(vocab => vocab !== selectedVocabulary);
        setVocabulary(updatedVocabulary);
        setSelectedVocabulary('');
        setHasChanges(true);
    };
    return (
        <div className="settings-container">
          <section>
            <h2>
              <FaStethoscope className="icon" /> Compte
            </h2>
            <p>Utilisateur: {username}</p>
          </section>
    
          <section>
            <h2>E-mail</h2>
            <div className="form-group">
              <label className="toggle-label">
                <input
                  type="checkbox"
                  checked={emailToggle}
                  onChange={handleToggleChange(setEmailToggle)}
                />
                Recevoir les rapports par e-mail
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                E-mail:
              </label>
              <input
                id="email"
                type="email"
                className="form-input"
                value={email}
                onChange={handleInputChange(setEmail)}
                placeholder="Entrez votre e-mail"
              />
            </div>
          </section>
    
          <section>
            <h2>Vocabulaire</h2>
            <p>
              Liste de vocabulaire:{' '}
              {vocabulary.length > 0 ? vocabulary.join(', ') : 'Vide'}
            </p>
            <div className="vocabulary-input">
              <input
                type="text"
                placeholder="Ajouter du vocabulaire"
                value={newVocab}
                onChange={(e) => setNewVocab(e.target.value)}
              />
              <button
                className="add-button"
                onClick={handleAddVocabulary}
                disabled={!newVocab.trim()}
              >
                Ajouter
              </button>
            </div>
            <div className="vocabulary-controls">
              <select
                value={selectedVocabulary}
                onChange={handleInputChange(setSelectedVocabulary)}
              >
                <option value="">Choisir un vocabulaire à supprimer</option>
                {vocabulary.map((vocab) => (
                  <option key={vocab} value={vocab}>
                    {vocab}
                  </option>
                ))}
              </select>
              <button
                disabled={!selectedVocabulary}
                onClick={handleRemoveVocabulary}
              >
                Supprimer
              </button>
            </div>
          </section>
    
          <section>
            <h2>Langue</h2>
            <label className="input-label">
              Langue des rapports:
              <select
                value={reportLanguage}
                onChange={handleInputChange(setReportLanguage)}
              >
                {Object.keys(LanguagesList.languages).map((lang) => (
                  <option key={lang} value={LanguagesList.languages[lang]}>
                    {lang}
                  </option>
                ))}
              </select>
            </label>
          </section>
    
          <section>
            <label className="input-label">
              Langue de dictée:
              <select
                value={dictationLanguage}
                onChange={handleInputChange(setDictationLanguage)}
              >
                {Object.keys(LanguagesList.languages).map((lang) => (
                  <option key={lang} value={LanguagesList.languages[lang]}>
                    {lang}
                  </option>
                ))}
              </select>
            </label>
          </section>
    
          <Button variant="primary" disabled={!hasChanges} onClick={handleSave}>
            Sauvegarder les réglages
          </Button>
        </div>
      );
    }
    
    export default Settings;