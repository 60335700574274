// Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

function Header({ handleLogout }) {
  return (
    <header className="app-header">
      <div className="header-left">
        <img src="logo.png" alt="Logo" className="logo" />
        <h1 className="header-title">Clarity</h1>
        <nav className="header-nav">
          <NavLink
            to="/reports"
            className="nav-link"
            activeClassName="active"
          >
            Rapports
          </NavLink>
          <NavLink
            to="/settings"
            className="nav-link"
            activeClassName="active"
          >
            Réglages
          </NavLink>
        </nav>
      </div>
      <div className="header-right">
        <button className="button button-light" onClick={handleLogout}>
          Se Déconnecter
        </button>
      </div>
    </header>
  );
}

export default Header;
