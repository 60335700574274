// ReportList.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { format, isToday } from 'date-fns';
import './ReportList.css';
import { FaCopy, FaSave, FaUndo } from 'react-icons/fa';
import Button from './Button';
import Notification from './Notification';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fonctions utilitaires en dehors du composant
const getLatestReportDate = (reports) => {
  if (reports.length === 0) return null;
  return reports.reduce((latestDate, report) => {
    const reportDate = new Date(report.date);
    return reportDate > latestDate ? reportDate : latestDate;
  }, new Date(reports[0].date));
};

const getOldestReportDate = (reports) => {
  if (reports.length === 0) return null;
  return reports.reduce((oldestDate, report) => {
    const reportDate = new Date(report.date);
    return reportDate < oldestDate ? reportDate : oldestDate;
  }, new Date(reports[0].date));
};

const mergeReports = (existingReports, newReports) => {
  const reportsMap = new Map();

  // Ajouter les rapports existants
  existingReports.forEach((report) => {
    reportsMap.set(report.messageID, report);
  });

  // Ajouter les nouveaux rapports
  newReports.forEach((report) => {
    if (!reportsMap.has(report.messageID)) {
      reportsMap.set(report.messageID, report);
    }
  });

  // Retourner un tableau de rapports triés par date décroissante
  return Array.from(reportsMap.values()).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
};

const transformStructuredToText = (structuredData) => {
  let textReport = '';
  Object.keys(structuredData).forEach((key) => {
    textReport += key + '\n' + structuredData[key] + '\n\n';
  });
  return textReport.trim();
};

// Fonctions pour sauvegarder et charger les rapports depuis le localStorage
const saveReportsToLocalStorage = (reportsData) => {
  localStorage.setItem('reports', JSON.stringify(reportsData));
};

const loadReportsFromLocalStorage = () => {
  const storedReports = localStorage.getItem('reports');
  return storedReports ? JSON.parse(storedReports) : [];
};

const saveLastRefreshDateToLocalStorage = (date) => {
  localStorage.setItem('lastRefreshDate', date);
};

const loadLastRefreshDateFromLocalStorage = () => {
  const storedDate = localStorage.getItem('lastRefreshDate');
  return storedDate ? new Date(storedDate) : null;
};

function ReportList() {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [originalReport, setOriginalReport] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastReportDate, setLastReportDate] = useState(null);
  const [refreshMessage, setRefreshMessage] = useState('');
  const [parsedStructuredOutput, setParsedStructuredOutput] = useState(null);
  const [notification, setNotification] = useState(null);
  const [filteredReports, setFilteredReports] = useState([]);
  const [refreshCountdown, setRefreshCountdown] = useState(0);
  const lastReportDateRef = useRef(lastReportDate);

  const isRefreshingRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [reportTypeFilter, setReportTypeFilter] = useState('');

  const limitPerPage = 50;


  // Initialisation du viewMode depuis le localStorage
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'Structured';
  });

  // Synchroniser lastReportDateRef avec lastReportDate
  useEffect(() => {
    lastReportDateRef.current = lastReportDate;
  }, [lastReportDate]);

  const handleRefresh = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;

    if (isRefreshingRef.current) {
      console.log('Veuillez attendre avant de rafraîchir à nouveau.');
      return;
    }

    isRefreshingRef.current = true;
    setRefreshCountdown(5);

    let dateToUse = lastReportDateRef.current;

    if (!dateToUse) {
      const storedLastRefreshDate = loadLastRefreshDateFromLocalStorage();
      if (storedLastRefreshDate) {
        setLastReportDate(storedLastRefreshDate);
        dateToUse = storedLastRefreshDate;
      } else {
        return;
      }
    }

    setLoading(true);
    setRefreshMessage('');

    try {
      const response = await axios.post(`${API_BASE_URL}/api/refreshReports`, {
        chatId,
        lastReportDate: dateToUse,
      });

      if (response.data.length > 0) {
        const newReports = response.data;

        // Fusionner les nouveaux rapports avec les rapports existants
        const existingReports = loadReportsFromLocalStorage();
        const combinedReports = mergeReports(existingReports, newReports);

        setReports(combinedReports);
        saveReportsToLocalStorage(combinedReports);

        setRefreshMessage(`${response.data.length} nouveaux rapports ajoutés.`);
        setNotification({ message: `${response.data.length} nouveaux rapports ajoutés.`, type: 'success' });

        // Mise à jour de lastReportDate
        const latestDate = getLatestReportDate(combinedReports);
        setLastReportDate(latestDate);
        saveLastRefreshDateToLocalStorage(latestDate.toISOString());

        // Mettre à jour hasMore
        setHasMore(response.data.length >= limitPerPage);
      } else {
        setNotification({ message: 'Vos rapports sont à jour.', type: 'classic' });
        setRefreshMessage('Vos rapports sont à jour.');
        setHasMore(false);
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement des rapports:', error);
    } finally {
      setLoading(false);
    }
  }, [limitPerPage]);

  const fetchInitialReports = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;

    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/fetchInitialReports`, {
        chatId,
        limit: limitPerPage,
      });
      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        const reportsData = response.data;

        // Mise à jour de l'état des rapports
        setReports(reportsData);

        // Enregistrement des rapports dans le localStorage
        saveReportsToLocalStorage(reportsData);

        const latestDate = getLatestReportDate(reportsData);

        // Mise à jour de lastReportDate
        setLastReportDate(latestDate);
        saveLastRefreshDateToLocalStorage(latestDate.toISOString());

        // Mettre à jour hasMore
        setHasMore(response.data.length >= limitPerPage);
      }
    } catch (error) {
      console.error('Error fetching initial reports:', error);
    } finally {
      setLoading(false);
    }
  }, [limitPerPage]);

  const loadMoreReports = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId || loading || !hasMore) return;

    setLoading(true);

    try {
      // Obtenir la date du rapport le plus ancien
      const oldestReportDate = getOldestReportDate(reports);

      const response = await axios.post(`${API_BASE_URL}/api/loadMoreReports`, {
        chatId,
        lastReportDate: oldestReportDate,
        limit: limitPerPage,
      });

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        const newReports = response.data;

        // Fusionner les nouveaux rapports avec les rapports existants
        const combinedReports = mergeReports(reports, newReports);

        setReports(combinedReports);
        saveReportsToLocalStorage(combinedReports);

        // Mettre à jour hasMore en fonction du nombre de rapports reçus
        setHasMore(response.data.length >= limitPerPage);
      }
    } catch (error) {
      console.error('Error loading more reports:', error);
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, limitPerPage, reports]);

  const handleReportClick = useCallback((report) => {
    setSelectedReport(report);
    setOriginalReport({ ...report });

    // Déterminer le mode d'affichage initial
    let initialViewMode = localStorage.getItem('viewMode') || 'Structured';

    if (initialViewMode === 'Structured' && report.reportType !== 'consultation') {
      // Si le rapport ne supporte pas le mode structuré, on passe en texte complet
      initialViewMode = 'Full Text';
    }

    setViewMode(initialViewMode);

    if (report.structured_output) {
      try {
        const parsed = JSON.parse(report.structured_output);
        setParsedStructuredOutput(parsed);
      } catch (error) {
        console.error('Error parsing structured_output:', error);
        setParsedStructuredOutput(null);
      }
    } else {
      setParsedStructuredOutput(null);
    }
  }, []);

  const handleViewModeChange = useCallback((mode) => {
    setViewMode(mode);
    localStorage.setItem('viewMode', mode);
  }, []);

  useEffect(() => {
    // Au chargement du composant, vérifier si des rapports sont stockés
    const storedReports = loadReportsFromLocalStorage();
    const storedLastRefreshDate = loadLastRefreshDateFromLocalStorage();

    if (storedReports.length > 0 && storedLastRefreshDate) {
      // Si des rapports sont stockés, les charger et définir lastReportDate
      setReports(storedReports);
      setLastReportDate(storedLastRefreshDate);

      // Appeler handleRefresh pour rafraîchir les rapports
      handleRefresh();
    } else {
      // Sinon, appeler fetchInitialReports pour charger les rapports initiaux
      fetchInitialReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reports.length > 0 && !selectedReport) {
      handleReportClick(reports[0]);
    }
  }, [reports, selectedReport, handleReportClick]);

  useEffect(() => {
    // Filtrer les rapports en fonction de la recherche et du filtre
    let filtered = reports;

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (report) =>
          report.name.toLowerCase().includes(lowerCaseQuery) ||
          report.finalReport.toLowerCase().includes(lowerCaseQuery)
      );
    }

    if (reportTypeFilter) {
      filtered = filtered.filter((report) => report.reportType === reportTypeFilter);
    }

    setFilteredReports(filtered);
  }, [reports, searchQuery, reportTypeFilter]);

  // Nouveau useEffect pour gérer le compteur de rafraîchissement
  useEffect(() => {
    if (refreshCountdown > 0) {
      const timerId = setTimeout(() => {
        setRefreshCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      isRefreshingRef.current = false;
    }
  }, [refreshCountdown]);

  const reportTypes = React.useMemo(() => {
    return [...new Set(reports.map((report) => report.reportType))];
  }, [reports]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const getFirstThreeLines = (text) => {
    if (!text) return '';

    // Vérifier si le texte est plus long que 80 caractères
    if (text.length > 80) {
      // Retourner les 80 premiers caractères suivis de '...'
      return text.substring(0, 80) + '...';
    }

    // Sinon, retourner le texte tel quel
    return text;
  };

  const handleUndo = () => {
    if (originalReport) {
      setSelectedReport({ ...originalReport });
      if (originalReport.structured_output) {
        try {
          const parsed = JSON.parse(originalReport.structured_output);
          setParsedStructuredOutput(parsed);
        } catch (error) {
          console.error('Error parsing structured_output:', error);
          setParsedStructuredOutput(null);
        }
      } else {
        setParsedStructuredOutput(null);
      }
    }
  };

  const handleSave = async () => {
    if (selectedReport) {
      const chatId = localStorage.getItem('chatId');
      if (!chatId) return;

      try {
        if (viewMode === 'Structured' && parsedStructuredOutput) {
          // Transforme le rapport structuré en texte
          selectedReport.finalReport = transformStructuredToText(parsedStructuredOutput);
        }

        if (parsedStructuredOutput) {
          const updatedStructuredOutput = JSON.stringify(parsedStructuredOutput);
          selectedReport.structured_output = updatedStructuredOutput;
        }

        await axios.post(`${API_BASE_URL}/updateReport`, {
          chatId,
          correctedVersion: selectedReport.finalReport,
          messageID: selectedReport.messageID,
          structured_output: selectedReport.structured_output,
        });

        // Mettre à jour le rapport dans l'état local
        setReports((prevReports) => {
          const updatedReports = prevReports.map((report) =>
            report.messageID === selectedReport.messageID
              ? { ...report, finalReport: selectedReport.finalReport, structured_output: selectedReport.structured_output }
              : report
          );

          // Sauvegarder les rapports mis à jour dans le localStorage
          saveReportsToLocalStorage(updatedReports);

          return updatedReports;
        });

        alert('Rapport sauvegardé avec succès');

      } catch (error) {
        console.error('Erreur lors de la sauvegarde du rapport:', error);
        alert('Erreur lors de la sauvegarde du rapport');
      }
    }
  };

  const handleCopy = () => {
    let contentToCopy = selectedReport.finalReport;

    if (viewMode === 'Structured' && parsedStructuredOutput) {
      contentToCopy = transformStructuredToText(parsedStructuredOutput);
    }

    navigator.clipboard
      .writeText(contentToCopy)
      .then(() => {
        setNotification({ message: 'Rapport copié dans le presse-papier', type: 'info' });
      })
      .catch((err) => {
        console.error('Erreur lors de la copie:', err);
        setNotification({ message: 'Erreur lors de la copie', type: 'error' });
      });
  };

  // Fonction pour ajuster la taille du textarea
  const autoResizeTextarea = (element) => {
    element.style.height = 'auto'; // Réinitialise la hauteur
    element.style.height = element.scrollHeight + 'px'; // Ajuste la hauteur en fonction du contenu
  };

  const renderStructuredOutput = () => {
    if (!parsedStructuredOutput) {
      return <p>Erreur de formatage des données structurées.</p>;
    }

    return Object.keys(parsedStructuredOutput).map((key, index) => (
      <div key={index} className="structured-report-section">
        <div className="structured-header">
          <button
            className="key-button"
            onClick={() => handleCopySection(parsedStructuredOutput[key])}
          >
            <FaCopy /> {key}
          </button>
        </div>
        <div className="structured-report-content">
          <textarea
            value={parsedStructuredOutput[key]}
            onInput={(e) => autoResizeTextarea(e.target)}
            onChange={(e) => {
              setParsedStructuredOutput((prevState) => ({
                ...prevState,
                [key]: e.target.value,
              }));
            }}
            className="report-textarea"
            rows={1}
            ref={(textarea) => {
              if (textarea) {
                autoResizeTextarea(textarea);
              }
            }}
          />
        </div>
      </div>
    ));
  };

  const handleCopySection = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setNotification({ message: 'Section copiée dans le presse-papier', type: 'info' });
      })
      .catch((err) => {
        console.error('Erreur lors de la copie:', err);
        setNotification({ message: 'Erreur lors de la copie', type: 'error' });
      });
  };

  const groupReportsByDay = (reports) => {
    return reports.reduce((groups, report) => {
      const reportDate = new Date(report.date);
      const dateKey = isToday(reportDate) ? "Aujourd'hui" : format(reportDate, 'dd/MM/yyyy');

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(report);
      return groups;
    }, {});
  };

  const groupedReports = groupReportsByDay(filteredReports);

  return (
    <div className="report-list-container">
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="report-list-container">
        <div className="report-list">
        <div className="refresh-button-container">
            <Button
              variant="primary"
              onClick={handleRefresh}
              disabled={refreshCountdown > 0}
            >
              {refreshCountdown > 0
                ? `Réessayer dans ${refreshCountdown} seconde${refreshCountdown > 1 ? 's' : ''}`
                : 'Récupérer les nouveaux rapports'}
            </Button>
          </div>

          {refreshMessage && <p className="refresh-message">{refreshMessage}</p>}

          {/* Barre de recherche et filtre */}
          <div className="filter-container">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <select
              value={reportTypeFilter}
              onChange={(e) => setReportTypeFilter(e.target.value)}
            >
              <option value="">Tous les types de rapport</option>
              {reportTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            {(searchQuery || reportTypeFilter) && (
              <button
                onClick={() => {
                  setSearchQuery('');
                  setReportTypeFilter('');
                }}
              >
                Réinitialiser les filtres
              </button>
            )}
          </div>

          {/* Liste des rapports filtrés */}
          {Object.keys(groupedReports).map((dateKey, idx) => (
            <div key={idx} className="report-group">
              <h2 className="report-group-title">{dateKey}</h2>
              <ul>
                {groupedReports[dateKey].map((report) => (
                  <li
                    key={report.messageID}
                    className="report-item"
                    onClick={() => handleReportClick(report)}
                  >
                    <h3>{capitalizeFirstLetter(report.name)}</h3>
                    <p className="report-preview">{getFirstThreeLines(report.finalReport)}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {filteredReports.length === 0 && <p>Aucun rapport disponible</p>}
          {loading && <p>Chargement des rapports...</p>}

          {/* Bouton pour charger plus de rapports */}
          {hasMore && (
            <Button variant="secondary" onClick={loadMoreReports}>
              Charger 50 rapports de plus...
            </Button>
          )}
        </div>

        {/* Détails du rapport sélectionné */}
        {selectedReport && (
          <div className="report-details">
            <div className="report-header">
              <h2 className="report-title">{capitalizeFirstLetter(selectedReport.name)}</h2>
              {parsedStructuredOutput && Object.keys(parsedStructuredOutput).length > 0 && (
                <div className="view-toggle">
                  <Button
                    variant={viewMode === 'Structured' ? 'success' : 'secondary'}
                    onClick={() => handleViewModeChange('Structured')}
                    disabled={viewMode === 'Structured'}
                  >
                    Structuré
                  </Button>
                  <Button
                    variant={viewMode === 'Full Text' ? 'success' : 'secondary'}
                    onClick={() => handleViewModeChange('Full Text')}
                    disabled={viewMode === 'Full Text'}
                  >
                    Texte complet
                  </Button>
                </div>
              )}
            </div>
            <div className="buttons-container">
              <Button variant="info" onClick={handleCopy}>
                <FaCopy /> Copier le rapport
              </Button>
              <Button variant="warning" onClick={handleUndo}>
                <FaUndo /> Annuler
              </Button>
              <Button variant="success" onClick={handleSave}>
                <FaSave /> Sauvegarder le rapport
              </Button>
            </div>

            {viewMode === 'Structured' && parsedStructuredOutput && Object.keys(parsedStructuredOutput).length > 0 ? (
              <div className="structured-report">{renderStructuredOutput()}</div>
            ) : (
              <textarea
                value={selectedReport.finalReport}
                onInput={(e) => autoResizeTextarea(e.target)}
                onChange={(e) =>
                  setSelectedReport({ ...selectedReport, finalReport: e.target.value })
                }
                className="report-textarea"
                rows={1}
                ref={(textarea) => {
                  if (textarea) {
                    autoResizeTextarea(textarea);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportList;
